html, body {
    margin: 0;
    padding: 0;
    font-family: 'SF Pro';
    height: 100%;
    display: flex;
    flex-direction: row; /* Ensure they are side by side initially */
}

* {
    box-sizing: border-box;
}

.left-section {
    background-color: white;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    padding: 20px;
    text-align: center;
    position: relative;
}

.typing-text {
    font-size: 4rem;
    font-weight: bold;
    color: #222;
    animation: typing 3s steps(20, end), blink 1s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid #222;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink {
    from {
        border-right-color: #222;
    }
    to {
        border-right-color: transparent;
    }
}

.right-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-divider {
    width: 2px;
    background-color: #770505;
    height: 100vh;
    position: absolute;
    left: 50%;
}

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 500px;
    text-align: center;
    box-sizing: border-box;
}

.signup-form h1 {
    margin-bottom: 20px;
    color: #333;
    font-size: 2.2rem;
}

.input-field {
    width: 50%;
    padding: 12px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    outline: none;
    background: #444;
    color: white;
    font-size: 1rem;
    box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.signup-button {
    width: 40%;
    padding: 15px;
    background: black;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup-button:hover {
    background: red;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    html, body {
        flex-direction: column; /* Stack sections on top of each other */
        height: auto;
    }

    .left-section, .right-section {
        width: 100%;
        height: auto; /* Allow sections to auto adjust in height */
    }

    .typing-text {
        font-size: 2.5rem;
    }

    .signup-container {
        width: 90%;
        padding: 20px;
        max-width: 400px;
    }

    .signup-form h1 {
        font-size: 1.8rem;
    }

    .input-field {
        font-size: 1.1rem; /* Larger font for better readability */
        padding: 15px;
    }

    .signup-button {
        font-size: 1.1rem;
        padding: 15px;
    }

    /* Improve placeholder visibility */
    .input-field::placeholder {
        font-size: 1rem; /* Ensure placeholder text is readable */
    }
}

@media (max-width: 480px) {
    .typing-text {
        font-size: 2rem;
    }

    .signup-form h1 {
        font-size: 1.6rem;
    }

    .input-field {
        font-size: 1.1rem; /* Larger font for readability */
        padding: 15px; /* Ensure padding is enough for touch interaction */
    }

    .signup-button {
        font-size: 1rem;
        padding: 15px;
    }

    .signup-container {
        padding: 15px;
        width: 100%; /* Take full width on mobile */
    }

    .input-field::placeholder {
        font-size: 1rem; /* Ensure placeholder text is visible */
    }

    .signup-button {
        padding: 15px; /* Increase padding for mobile */
        font-size: 1rem; /* Maintain larger font for readability */
    }
}
